import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Article = ({ title, image, subtitle, createdAt, author, id }) => {
  const renderImage = getImage(image.gatsbyImageData)
  return (
    <div className="xl:w-1/4 lg:w-1/3 sm:w-1/2 w-full m-1 rounded-xl">
      <div className="w-full h-1/5">
        <GatsbyImage
          image={renderImage}
          className="w-full h-full aspect-square rounded-t-xl"
        />
      </div>
      <div className="w-full h-4/5 flex flex-col justify-center items-center p-4 bg-white rounded-b-xl text-ellipsis">
        <div className="w-full flex justify-center items-center my-2">
          <h1 className="xl:text-2xl text-xl text-center text-text-primary uppercase font-bold">
            {title}
          </h1>
        </div>
        <div className="w-full flex justify-start items-center my-2">
          <p className="xl:text-lg text-sm text-left text-text-primary">
            {subtitle}
          </p>
        </div>
        <div className="w-full flex justify-start items-center my-2">
          <p className="xl:text-lg text-sm text-left text-text-primary">
            {createdAt}
          </p>
        </div>
        <div className="w-full flex justify-start items-center my-2">
          <p className="xl:text-lg text-sm text-left text-text-primary">
            by{" "}
            <b>{String(author)[0].toUpperCase() + String(author).slice(1)}</b>
          </p>
        </div>
        <div className="w-full flex justify-center items-center my-2">
          <a
            href={`/articles/${id}`}
            className="xl:text-lg text-sm text-center text-text-primary btn btn-sm rounded-2xl"
          >
            read more
          </a>
        </div>
      </div>
    </div>
  )
}

export default function AllArticle() {
  const data = useStaticQuery(graphql`
    query ArticlePageQuery {
      allContentfulArticle(sort: { fields: createdAt }) {
        nodes {
          author
          content {
            raw
          }
          createdAt(formatString: "DD MMMM YYYY")
          headerImage {
            gatsbyImageData(
              resizingBehavior: CROP
              formats: PNG
              aspectRatio: 1
            )
          }
          subtitle
          title
          contentful_id
        }
      }
    }
  `)

  const { nodes } = data.allContentfulArticle
  return (
    <div className="w-full flex flex-col justify-center items-center px-1 my-5">
      <div className="lg:w-3/4 w-full px-3 flex flex-col justify-center items-center">
        <div className="2xl:text-5xl xl:text-4xl md:text-2xl text-2xl 2xl:[line-height:70px] xl:[line-height:50px]  font-helvetica font-bold text-text-primary uppercase md:text-left text-center mb-2">
          OUR ARTICLES
        </div>
      </div>

      <div className="md:w-10/12 w-full flex md:flex-row md:flex-wrap flex-col justify-center items-center mx-2 resource-bg-gradient rounded-xl py-10 px-5 my-2">
        {nodes.map((node, id) => (
          <Article
            key={id}
            title={node.title}
            subtitle={node.subtitle}
            createdAt={node.createdAt}
            author={node.author}
            image={node.headerImage}
            id={node.contentful_id}
          />
        ))}
      </div>
    </div>
  )
}
